import { ReactNode, useState } from 'react';
import WalletInfoContext from 'src/contexts/WalletInfoContext';
//provider是context对象的组件
export function WalletInfoProvider({ children }: { children: ReactNode }) {
  const [metamaskname, setName] = useState('');
  const [metamaskuuid, setUuid] = useState('');
  const [metamaskrdns, setRdns] = useState('');

  return (
    <WalletInfoContext.Provider value={{ metamaskname, metamaskuuid, metamaskrdns, setName, setUuid, setRdns }}>
      {children}
    </WalletInfoContext.Provider>
  );
}
