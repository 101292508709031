import { Route, Routes } from 'react-router-dom';
import Home from 'src/pages/home';
import Docs from 'src/pages/docs';
import About from 'src/pages/about';
import { StandardLayout } from 'src/layouts';

export default function ApplicationRoutes() {
  return (
    <Routes>
      <Route element={<StandardLayout />}>
        <Route path="/docs" element={<Home />} />
        <Route path="/" element={<Docs />} />
        <Route path="/about" element={<About />} />
      </Route>
    </Routes>
  );
}
