import React from 'react';
//存放wallet的信息，定义了一个context
interface WalletInfoContextProps {
  metamaskname: string; //当前选中的账户名
  metamaskuuid: string; //当前选中的账户uuid
  metamaskrdns: string; //当前选中的账户rdns
  setName: React.Dispatch<React.SetStateAction<string>>;
  setUuid: React.Dispatch<React.SetStateAction<string>>;
  setRdns: React.Dispatch<React.SetStateAction<string>>;
}

const WalletInfoContext = React.createContext<WalletInfoContextProps>({
  metamaskname: '',
  metamaskuuid: '',
  metamaskrdns: '',
  setName: () => undefined,
  setUuid: () => undefined,
  setRdns: () => undefined,
});
export default WalletInfoContext;
