import { useCallback, useContext, useEffect, useState, ReactNode } from 'react';
import { Eip6963ProviderContext, DataSourceEip6963Provider } from 'src/contexts/Eip6963ProviderContext';
import WalletInfoContext from 'src/contexts/WalletInfoContext'; //钱包信息和更新函数
import { formatAddress } from 'src/utils';
import toast from 'react-hot-toast';
import { Buffer } from 'buffer';

type SelectedAccountByWallet = Record<string, string | null>;
export const Eip6963Provider = ({ children }: { children: ReactNode }) => {
  const [wallets, setWallets] = useState<Record<string, EIP6963ProviderDetail>>({}); //钱包提供者的信息
  const [walletStatus, setWalletStatus] = useState(''); //钱包状态
  const [selectedWalletUuid, setSelectedWalletUuid] = useState<string | null>(null); //选择的钱包uuid
  const [selectedAccountByWalletUuid, setSelectedAccountByWalletUuid] = useState<SelectedAccountByWallet>({}); //选择的钱包账户
  const { setName, setUuid, setRdns } = useContext(WalletInfoContext); //钱包信息和更新函数
  const [ethereumResult, setEthereumResult] = useState(''); //以太坊sign结果

  useEffect(() => {
    if (selectedWalletUuid != null) {
      const selectedWallet = wallets[selectedWalletUuid];
      const selectedAccount = selectedAccountByWalletUuid[selectedWalletUuid];
      if (selectedWallet) {
        setName(selectedAccount ? formatAddress(selectedAccount) : '');
        setUuid(selectedWallet.info.uuid);
        setRdns(selectedWallet.info.rdns);
      }
    }
  }, [setName, setUuid, setRdns, selectedWalletUuid, wallets, selectedAccountByWalletUuid]);

  // Find out about all providers by using EIP-6963
  useEffect(() => {
    function onAnnouncement(event: EIP6963AnnounceProviderEvent) {
      // Only handle events from Metamask
      if (event.detail.info.name === 'MetaMask') {
        setWallets((currentWallets) => ({
          ...currentWallets,
          [event.detail.info.uuid]: event.detail,
        }));

        setSelectedWalletUuid(event.detail.info.uuid);

        checkIsLoggedIn(event.detail).catch((err) => {
          console.error('Failed to check if logged in:', err);
        });
      }
    }

    window.addEventListener('eip6963:announceProvider', onAnnouncement);
    window.dispatchEvent(new Event('eip6963:requestProvider'));
    return () => window.removeEventListener('eip6963:announceProvider', onAnnouncement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIsLoggedIn = async (wallet: EIP6963ProviderDetail) => {
    const accounts: string[] = (await wallet.provider.request({ method: 'eth_accounts' })) as string[];

    if (accounts.length > 0) {
      connectWallet(wallet).catch((err) => {
        throw err;
      });
    }
  };
  const connectWallet = useCallback(async (wallet: EIP6963ProviderDetail) => {
    try {
      setWalletStatus('loading');
      const accounts = (await wallet.provider.request({
        method: 'eth_requestAccounts',
        params: [{ eth_accounts: {} }],
      })) as string[];
      if (accounts?.[0]) {
        setSelectedWalletUuid(wallet.info.uuid);
        setSelectedAccountByWalletUuid((currentAccounts) => ({
          ...currentAccounts,
          [wallet.info.uuid]: accounts[0],
        }));
      }
      setWalletStatus('connected');
    } catch (error) {
      setWalletStatus('disconnected');
      console.error('Failed to connect to provider:', error);
    }
  }, []);

  const signInWithEthereum = useCallback(async () => {
    if (wallets && selectedWalletUuid && wallets[selectedWalletUuid]) {
      try {
        const provider = wallets[selectedWalletUuid].provider;
        const accounts = (await provider.request({ method: 'eth_requestAccounts' })) as string[];
        const domain = window.location.host;
        const from = accounts[0];
        const siweMessage = `${domain} wants you to sign in with your Ethereum account:\n${from}\n\nI accept the MetaMask Terms of Service: https://community.metamask.io/tos\n\nURI: https://${domain}\nVersion: 1\nChain ID: 1\nNonce: 32891757\nIssued At: 2021-09-30T16:25:24.000Z`;

        const msg = `0x${Buffer.from(siweMessage, 'utf8').toString('hex')}`;
        const sign: string = (await provider.request({
          method: 'personal_sign',
          params: [msg, from, 'Example password'],
        })) as string;

        console.log('sign', sign);

        setEthereumResult(sign);
      } catch (error) {
        console.error('Failed to connect to provider:', error);
        throw error;
      }
    } else {
      toast.error('No wallet selected');
    }
  }, [selectedWalletUuid, wallets]);

  const contextValue: DataSourceEip6963Provider = {
    wallets,
    walletStatus,
    ethereumResult,
    selectedWallet: selectedWalletUuid === null ? null : wallets[selectedWalletUuid],
    selectedAccount: selectedWalletUuid === null ? null : selectedAccountByWalletUuid[selectedWalletUuid],
    connectWallet,
    signInWithEthereum,
  };

  return <Eip6963ProviderContext.Provider value={contextValue}>{children}</Eip6963ProviderContext.Provider>;
};
