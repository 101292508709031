import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import ApplicationRoutes from 'src/routes/ApplicationRoutes.tsx';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { queryClient } from 'src/utils';
import { Eip6963Provider } from 'src/providers/Eip6963Provider.tsx';
import { WalletInfoProvider } from 'src/providers/WalletInfoProvider.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <div className="h-screen">
      {/* 注意先后顺序与父子关系，内部的能拿到外部的数据，外部的拿不到内部的数据 */}
      <QueryClientProvider client={queryClient}>
        <WalletInfoProvider>
          <Eip6963Provider>
            <BrowserRouter>
              <ApplicationRoutes />
              <Toaster position="top-center" containerStyle={{ zIndex: 99999 }} />
            </BrowserRouter>
          </Eip6963Provider>
        </WalletInfoProvider>
      </QueryClientProvider>
    </div>
  </React.StrictMode>,
);
