import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export interface ProofGenerateAndSubmitReq {
  encrypted_prompt: number[];
  model: string;
}

interface ResponseType {
  model: string;
  encrypted_prompt: number[];
  proof: {
    status: string;
    contractAddress: string;
    contractUrl: string;
    transactionHash: string;
    transactionUrl: string;
  };
  result: string;
  status: number;
  id: number;
}

interface ResponseType2 {
  model: string;
  encrypted_prompt: number[];
  proof: {
    status: string;
    contractAddress: string;
    contractUrl: string;
    transactionHash: string;
    transactionUrl: string;
  };
  result: string;
  status: number;
  id: number;
}

export function useProofGenerateAndSubmit() {
  return useMutation({
    mutationFn: async (proof: ProofGenerateAndSubmitReq) => {
      const fetcher = await fetch(`${import.meta.env.VITE_BASE_URL}/task`, {
        body: JSON.stringify({
          ...proof,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (fetcher.status === 201) {
        // remove target query from cache
        // TODO: 'Implement the return type for this function' @Moonlit1997;
        const res: ResponseType = await (fetcher.json() as Promise<ResponseType>);
        return res;
      } else {
        if (fetcher.status === 500) {
          toast.error('Server error');
          throw new Error('server error');
        }
        // Improve other http code and messages. @Moonlit1997
      }
    },
  });
}

export function useGetProof(taskID?: string) {
  const [isPolling, setIsPolling] = useState(true);

  const queryInfo = useQuery({
    enabled: !!taskID,
    queryFn: async () => {
      const fetcher = await fetch(`${import.meta.env.VITE_BASE_URL}/task/${taskID}`, {
        method: 'GET',
      });
      if (fetcher.status === 200) {
        const res: ResponseType2 = await (fetcher.json() as Promise<ResponseType>);
        return res;
      } else {
        if (fetcher.status === 500) {
          throw new Error('GetFilesTypeFailed');
        }
        // Improve other http code and messages. @Moonlit1997
      }
    },
    queryKey: ['GetProof', taskID],
    refetchInterval: isPolling ? 5000 : false, // 设置轮询间隔为 5000 毫秒，即 5 秒，
  });

  return { ...queryInfo, isPolling, setIsPolling };
}
