import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
interface ResponseType {
  input: string;
  result: number[];
}

export function useEncrypt() {
  return useMutation({
    mutationFn: async (input: string) => {
      const fetcher = await fetch(`${import.meta.env.VITE_BASE_URL}/fhe/encrypt`, {
        body: JSON.stringify({
          input,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (fetcher.status === 201) {
        const res: ResponseType = await (fetcher.json() as Promise<ResponseType>);
        return res;
      } else {
        if (fetcher.status === 500) {
          toast.error('Server error');
          throw new Error('server error');
        }
        // Improve other http code and messages. @Moonlit1997
      }
    },
  });
}
