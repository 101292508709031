import { Fragment, useContext, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ConnectButton } from 'src/components/ConnectButton/index';
import { Eip6963ProviderContext } from 'src/contexts/Eip6963ProviderContext';

interface HomeDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function HomeDialog({ open, setOpen }: HomeDialogProps) {
  const { walletStatus } = useContext(Eip6963ProviderContext);
  useEffect(() => {
    if (walletStatus == 'connected') {
      setOpen(false);
    }
  }, [walletStatus, setOpen]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-flex  flex-col items-center justify-center gap-10 rounded-3xl bg-black px-12 py-16 text-white transition-all">
                <p className="h-7 text-center font-DMSansRegular text-2xl uppercase">Connect wallet</p>
                <div className={`rounded-xl border-2 ${walletStatus == 'loading' ? '  border-btn1' : 'border-black'}`}>
                  <ConnectButton />
                </div>
                {walletStatus == 'disconnected' ? (
                  <p className="font-DMSansRegular text-xl text-[#FF67CD]">Connection declined</p>
                ) : (
                  <p className="w-[338px] font-InterRegular text-[10px] capitalize leading-4 opacity-70">
                    Wallets are provided by External Providers and by selecting you
                    <br /> agree to Terms of those Providers. Your access to the wallet <br />
                    might be reliant on the External Provider being operational.
                  </p>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
