// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import HomeTextBox from 'src/components/HomeTextBox';
import switchBtn from 'src/assets/switchBtn.svg';

const NavButtons = () => {
  const swiper = useSwiper();
  return (
    <>
      <div
        className="absolute left-1 right-auto top-1/2 flex h-[33px] w-[33px] -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center rounded-full border border-black bg-white p-2 hover:bg-[#f4fed6] sm:h-[50px] sm:w-[50px] sm:p-4"
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <img src={switchBtn} className="h-full w-full rotate-180" />
      </div>
      <div
        className="absolute left-auto right-1 top-1/2 flex h-[33px] w-[33px] -translate-y-1/2 translate-x-1/2 cursor-pointer items-center rounded-full border border-black bg-white p-2 hover:bg-[#f4fed6] sm:h-[50px] sm:w-[50px] sm:p-4"
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <img src={switchBtn} className="h-full w-full" />
      </div>
    </>
  );
};

export default function Home() {
  return (
    <div className="h-full">
      {/* The first page */}
      <div className="my-8 flex h-full w-full flex-col items-center justify-center gap-[65px] sm:flex-row">
        <div className="my-24 flex flex-1 flex-row-reverse sm:my-0">
          <div className=" w-[251px] sm:w-[471px]">
            <div className="text-center sm:mx-0 sm:mb-28">
              <div className="mb-16 sm:mb-0">
                <h1
                  style={{ lineHeight: 'normal' }}
                  className="mb-5 text-center font-InterBold text-[32px] font-bold tracking-normal text-black sm:mb-0 sm:text-left sm:text-5xl"
                >
                  AI <span className="text-greentext">Inference</span> Network With
                  <span className="text-greentext"> vFHE</span>
                </h1>
                <p className=" mt-3 text-center font-InterRegular text-base font-normal capitalize tracking-normal text-phonecontent sm:text-left sm:text-lg sm:text-neutral-600">
                  Sight AI is a blockchain dedicated to LLM inference tasks, preserving privacy through Verifiable Fully
                  Homomorphic Encryption.{' '}
                </p>
              </div>
              <div className="mt-10 flex flex-col items-center justify-start gap-x-6 sm:flex-row">
                <div className="flex  h-[45px] w-[240px] items-center justify-center rounded-xl bg-black hover:opacity-80 sm:h-[48px]  sm:w-[184px]">
                  <p className="text-center font-InterRegular text-sm font-medium uppercase tracking-wider text-white sm:text-lg">
                    Join Waitlist
                  </p>
                </div>
                <div className="mt-5 flex h-[45px] w-[240px] items-center justify-center rounded-xl border border-black bg-white hover:bg-hoverbtn1 sm:mt-0 sm:h-[48px] sm:w-[238px]">
                  <p className="text-center font-InterRegular text-sm font-medium uppercase tracking-wider sm:text-lg ">
                    Apply partnership
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-28 hidden flex-1 sm:mb-0 sm:block sm:w-[800px] 2xl:w-[1033px]">
          <Swiper
            effect={'coverflow'}
            initialSlide={1}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 45, // slide做3d倾斜时的角度
              stretch: 300, //  拉伸
              depth: 400, //  深度
              modifier: 1, // 修正值
              slideShadows: true, //  页面阴影
            }}
            slideActiveClass="swiper-slide-active"
            navigation={false}
            modules={[Autoplay, EffectCoverflow, Navigation]}
            autoplay={{
              delay: 250000,
              disableOnInteraction: false,
            }}
            simulateTouch={false}
            loop={false}
            className="mySwiper"
          >
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="llama7b" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="stablediffusion" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="moremodel" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="mb-28 block w-screen sm:mb-0 sm:hidden sm:w-[800px] 2xl:w-[1033px]">
          <Swiper
            effect={'coverflow'}
            initialSlide={0}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 45, // slide做3d倾斜时的角度
              stretch: 100, //  拉伸
              depth: 400, //  深度
              modifier: 1, // 修正值
              slideShadows: true, //  页面阴影
            }}
            slideActiveClass="swiper-slide-active"
            navigation={false}
            modules={[Autoplay, EffectCoverflow, Navigation]}
            autoplay={{
              delay: 250000,
              disableOnInteraction: false,
            }}
            simulateTouch={false}
            loop={false}
            className="mySwiper"
          >
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="llama7b" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="stablediffusion" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
            <SwiperSlide className="m-auto !w-auto p-1">
              {({ isActive }) => (
                <>
                  <HomeTextBox type="moremodel" />
                  {isActive && <NavButtons />}
                </>
              )}
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="absolute right-1/4 hidden h-[468px] w-[468px] rounded-full bg-lime-400 opacity-60 blur-3xl sm:block" />
        <div className="absolute -z-10 block h-[468px] w-[468px] rounded-full bg-lime-400 opacity-60 blur-[100px] sm:hidden" />
      </div>
    </div>
  );
}
