// Import Swiper React components
import bannerPerson from 'src/assets/img/bannerPerson.svg';
import bannerPerson2 from 'src/assets/img/bannerPerson2.svg';
import banner1 from 'src/assets/img/banner1.svg';
import banner2 from 'src/assets/img/banner2.svg';
import banner3 from 'src/assets/img/banner3.svg';
import banner4 from 'src/assets/img/banner4.svg';
import banner5 from 'src/assets/img/banner5.svg';
import banner6 from 'src/assets/img/banner6.svg';
export default function About() {
  return (
    <div>
      {/* The third page */}
      <div className="sm:min-h-calc-16 mx-auto flex w-[335px] flex-col items-center justify-start sm:w-[1440px]">
        <div className=" flex -translate-x-8 translate-y-2 transform items-center sm:-mt-10 sm:ml-36 sm:mr-auto sm:translate-x-0 sm:translate-y-10">
          <img className="hidden sm:block" src={bannerPerson}></img>
          <img className="block sm:hidden" src={bannerPerson2}></img>
          <div className="relative mt-4 sm:ml-12 sm:max-h-[67px] sm:max-w-[458px] ">
            <p className="text-center font-InterBold text-2xl leading-10 text-gray-900 sm:text-5xl">
              AI dapp Use cases
            </p>
          </div>
        </div>
        <div className="relative grid max-h-[325px] max-w-[335px] grid-cols-2 gap-0.5 overflow-hidden bg-white sm:mx-0 sm:max-h-[508px] sm:max-w-[1200px] sm:grid-cols-3">
          <div className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-gray-400/5 p-[10px] shadow-bottom-black-phone sm:mb-6 sm:mr-6 sm:h-[236px] sm:max-w-[384px] sm:p-8 sm:shadow-bottom-black">
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner1} alt="Transistor" />
            <p className=" w-[141px] text-center font-InterBold text-xs font-bold sm:w-[334px] sm:text-2xl">
              Smart Contract Copilot
            </p>
          </div>
          <div className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-[#ABF600] p-[10px] shadow-bottom-black-phone sm:mb-6 sm:mr-6 sm:h-[236px] sm:max-w-[384px] sm:p-8  sm:shadow-bottom-black">
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner2} alt="Reform" />
            <p className=" text-center font-InterBold text-xs font-bold sm:text-2xl">
              On-Chain AlGC
              <br />
            </p>
          </div>
          <div className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-black p-[10px] shadow-bottom-black-phone sm:mb-6 sm:h-[236px] sm:max-w-[384px] sm:p-8  sm:shadow-bottom-black">
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner3} alt="Tuple" />
            <p className="text-center font-InterBold text-xs font-bold text-white sm:text-2xl">
              Trading
              <br />
            </p>
          </div>
          <div
            className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-black p-[10px] shadow-bottom-black-phone sm:mb-6 sm:mr-6 sm:h-[236px] sm:max-w-[384px] sm:p-8   sm:shadow-bottom-black
"
          >
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner4} alt="Laravel" />
            <p className=" text-center font-InterBold text-xs font-bold text-white sm:text-2xl">
              Personal Al Assistant
            </p>
          </div>
          <div
            className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-gray-400/5 p-[10px] shadow-bottom-black-phone sm:mb-6 sm:mr-6 sm:h-[236px] sm:max-w-[384px] sm:p-8  sm:shadow-bottom-black
"
          >
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner5} alt="SavvyCal" />
            <p className="text-center font-InterBold text-xs font-bold sm:text-2xl">
              Risk Monitor
              <br />
            </p>
          </div>
          <div
            className="mb-[10px] mr-[10px] flex h-[100px] max-w-[162.5px] flex-col items-center justify-center space-y-3 rounded-xl border border-black bg-[#ABF600] p-[10px] shadow-bottom-black-phone sm:mb-6 sm:h-[236px] sm:max-w-[384px] sm:p-8 sm:shadow-bottom-black 
"
          >
            <img className="h-[31px] w-full object-contain sm:h-[72px]" src={banner6} alt="Statamic" />
            <p className="text-center font-InterBold text-xs font-bold sm:text-2xl">
              Al-Enhanced AMM
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
