import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Outlet, Link } from 'react-router-dom';
import { Eip6963ProviderContext } from 'src/contexts/Eip6963ProviderContext'; //钱包提供者的信息
import HomeDialog from 'src/components/HomeDialog';
import { useState, useContext } from 'react';
import WalletInfoContext from 'src/contexts/WalletInfoContext';
import xsvg from 'src/assets/img/x.svg';
import githubsvg from 'src/assets/img/github.svg';
import telegramsvg from 'src/assets/img/telegram.svg';
import logotextsvg from 'src/assets/img/logotext.svg';
import mediumsvg from 'src/assets/img/Medium.svg';
import logosvg from 'src/assets/img/logo.svg';
import bars3svg from 'src/assets/img/bars3.svg';
import xmarksvg from 'src/assets/img/xmark.svg';
import toast from 'react-hot-toast';

const social = [
  {
    name: 'x',
    href: 'https://x.com/thesightai',
    icon: xsvg,
  },
  {
    name: 'GitHub',
    href: 'https://github.com/sight-ai',
    icon: githubsvg,
  },
  {
    name: 'telegram',
    href: 'https://t.me/sightai',
    icon: telegramsvg,
  },
  {
    name: 'medium',
    href: 'https://medium.com/@theSightAI',
    icon: mediumsvg,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function StandardLayout() {
  const { metamaskname, setName } = useContext(WalletInfoContext);
  const { ethereumResult, signInWithEthereum } = useContext(Eip6963ProviderContext);

  const isLogged = metamaskname !== '';
  const isLoggedWithEthereum = ethereumResult !== '';

  const [open, setOpen] = useState(false); //弹窗状态
  const userNavigation = [
    { name: metamaskname, href: '#' },
    {
      name: isLoggedWithEthereum ? 'Ethereum is logged' : 'Sign in with ethereum',
      href: '#',
      onClick: () => {
        if (!isLoggedWithEthereum) {
          signInWithEthereum().catch(() => {
            toast.error('Failed to connect wallet');
          });
        }
      },
    },
    {
      name: 'DISCONNECT',
      href: '#',
      onClick: () => {
        if (metamaskname !== '') {
          setName('');
        }
        toast.success('disconnect wallet');
      },
    },
  ];
  const [navigation, setNavigation] = useState([
    // { name: 'About us', href: '/about', current: false, type: 'text' },
    { name: 'Docs', href: 'https://sightai.gitbook.io/docs', current: false, type: 'text' },
    { name: 'Dashboard', href: '/', current: false, type: 'text' },
  ]);

  const handleButtonClick = () => {
    if (!isLogged) {
      setOpen(true);
    }
  };

  return (
    <div>
      {/* 登录的弹窗 */}
      <HomeDialog open={open} setOpen={setOpen} />
      <div className="flex h-full min-h-screen flex-col">
        {/* 导航栏 */}
        <Disclosure as="nav" className="z-10 bg-white">
          {/* open用于控制是否展开菜单以及按钮的样式 */}
          {({ open }) => (
            <>
              <div className="flex h-[3.75rem] w-screen px-5 sm:h-[7.5rem] lg:px-[50px] xl:px-[80px] 2xl:px-[120px] 3xl:px-[220px]">
                <div className="flex w-full ">
                  {/* Logo 可点击跳转,当手机端展开下拉框的时候隐藏 */}
                  {!open ? (
                    <Link className="flex flex-shrink-0 items-center" to={'/'}>
                      <img
                        className="mr-[0.5625rem] h-[1.875rem] w-[1.625rem] sm:mr-3 sm:h-[2.5625rem] sm:w-[2.25rem]"
                        src={logosvg}
                        alt="SightAI Logo"
                      />
                      <img
                        className="h-[0.75rem] w-[4.125rem] sm:h-[1.0625rem] sm:w-[5.625rem]"
                        src={logotextsvg}
                        alt="SightAI Logo Text"
                      />
                    </Link>
                  ) : (
                    <></>
                  )}
                  {/* 导航栏 */}
                  <div className="hidden flex-1 space-x-[3.75rem] sm:-my-px sm:ml-auto sm:mr-10 lg:flex lg:justify-end">
                    {navigation.map((item, index) =>
                      item.name === 'Dashboard' ? (
                        <span
                          key={item.name}
                          className=" pointer-events-none inline-flex select-none items-center font-InterRegular font-medium text-noselect 2xl:text-lg"
                        >
                          {item.name}
                        </span>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.href || '#'}
                          onClick={() => {
                            setNavigation(
                              navigation.map((navItem, navIndex) => {
                                if (navIndex === index) {
                                  return { ...navItem, current: true };
                                } else {
                                  return { ...navItem, current: false };
                                }
                              }),
                            );
                          }}
                          className={classNames(
                            'inline-flex items-center font-InterRegular text-lg font-medium hover:text-black',
                            item.current ? 'text-black' : 'text-noselect',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ),
                    )}
                  </div>
                  {/* 导航按钮 */}
                  <button
                    onClick={() => {
                      window.open('https://forms.gle/VtSf5VSSNDKbkTKp8', '_blank');
                    }}
                    className="m-auto hidden h-[2.625rem] w-[11.625rem] items-center rounded-3xl border border-black text-center font-InterRegular font-medium uppercase text-black transition hover:-translate-x-[6px] hover:-translate-y-[6px] hover:shadow-offset-btn-hover  lg:block 2xl:text-lg"
                  >
                    JOIN WAITLIST
                  </button>
                </div>
                {/* Profile dropdown 下拉菜单*/}
                <div className="hidden sm:ml-6 lg:flex lg:items-center">
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button onClick={handleButtonClick} className="relative flex  items-center">
                        <span className="sr-only">Open user menu</span>
                        <a className="flex h-[2.625rem] items-center justify-center whitespace-nowrap rounded-3xl bg-btn1 px-[30px] py-[10px]  text-center font-InterRegular font-medium uppercase transition hover:-translate-x-[6px] hover:-translate-y-[6px]  hover:shadow-offset-btn-hover 2xl:text-lg">
                          {isLogged ? metamaskname : 'CONNECT WALLET'}
                        </a>
                      </Menu.Button>
                    </div>
                    {/* TODO:链接metamask后的下拉菜单 */}
                    {isLogged && (
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-2xl bg-white px-2 py-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                  )}
                                  onClick={() => {
                                    if (item.onClick) {
                                      item.onClick();
                                    }
                                  }}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    )}
                  </Menu>
                </div>
                {/* Mobile menu button 手机按钮*/}
                <div className=" flex items-center lg:hidden">
                  <Disclosure.Button className="bg-white text-gray-400">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <img src={xmarksvg} alt="" className="block h-[1.25rem] w-[1.25rem]" aria-hidden="true" />
                    ) : (
                      <img src={bars3svg} alt="" className="block h-[1.25rem] w-[1.25rem]" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
              {/* Mobile menu 手机下拉菜单*/}
              <Disclosure.Panel className="absolute z-20 w-full bg-white shadow-phone-menu lg:hidden">
                <div className="flex h-[248px] flex-col items-center justify-center text-center">
                  {/* about us */}
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={'mb-5 block  font-InterRegular  text-base font-medium text-black'}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  {/* join waitlist */}
                  <button
                    onClick={() => {
                      window.open('https://forms.gle/VtSf5VSSNDKbkTKp8', '_blank');
                    }}
                    className=" mx-auto mb-[1.25rem] h-11 w-[16.25rem] items-center rounded-3xl border border-black px-[0.875rem] text-center font-InterRegular text-base font-medium uppercase text-black "
                  >
                    JOIN WAITLIST
                  </button>
                  <button className="mx-auto mb-10 flex max-w-md rounded-full bg-white text-sm" disabled={false}>
                    <a className="inline-flex h-11 w-[16.25rem] items-center justify-center whitespace-nowrap rounded-3xl bg-btn1 px-4  text-center font-InterRegular text-base font-medium uppercase text-black ">
                      {isLogged ? 'DISCONNECT' : 'CONNECT WALLET'}
                    </a>
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="flex flex-1 flex-col">
          {/* 主要内容 */}
          <main className="flex-1">
            <Outlet />
          </main>
          {/* 底部导航栏 */}
          <footer className="z-10 hidden h-[7.5rem] bg-black lg:block">
            <div className=" flex h-full items-center justify-between border-t border-white border-opacity-20 px-5  lg:px-[50px] xl:px-[80px] 2xl:px-[120px] 3xl:px-[220px]">
              {/* 底部声明 */}
              <p className="order-1 font-InterRegular text-base font-normal text-[#BFBFBF] ">
                2024 Sight AI. Palo Alto, California, 94301. All Rights Reserved.
              </p>
              {/* 底部链接 */}
              <div className="order-2 flex space-x-[20px]">
                {social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                    <span className="sr-only">{item.name}</span>
                    <img src={item.icon} className=" w-[22px]" aria-hidden="true"></img>
                  </a>
                ))}
              </div>
            </div>
          </footer>
          {/* 手机底部导航栏 */}
          <footer className="z-10 block h-[11.125rem] bg-black lg:hidden">
            <div className=" flex h-full flex-col items-center justify-between border-0 py-[3.125rem]">
              {/* 底部声明 */}
              <p className="order-2 text-center font-InterRegular text-sm font-normal text-white">
                2024 Sight AI. Palo Alto, California, 94301.
              </p>
              <p className="order-2 text-center font-InterRegular text-sm font-normal text-white">
                All Rights Reserved.
              </p>
              {/* 底部链接 */}
              <div className="order-1 mb-5  flex space-x-6">
                {social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                    <span className="sr-only">{item.name}</span>
                    <img src={item.icon} className="h-5 w-6" aria-hidden="true"></img>
                  </a>
                ))}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
