import { useContext } from 'react';
import toast from 'react-hot-toast';
import { Eip6963ProviderContext } from 'src/contexts/Eip6963ProviderContext'; //钱包提供者的信息

export const ConnectButton = () => {
  const { wallets, connectWallet } = useContext(Eip6963ProviderContext);

  const onConnectWallet = (wallet: EIP6963ProviderDetail) => {
    connectWallet(wallet).catch(() => {
      toast.error('Failed to connect wallet');
    });
  };

  return (
    <div className="flex h-full w-full flex-col items-center gap-4">
      {Object.keys(wallets).length > 0 ? (
        Object.values(wallets).map((provider: EIP6963ProviderDetail) => (
          <button
            key={provider.info.uuid}
            onClick={() => {
              onConnectWallet(provider);
            }}
          >
            {/* 监测钱包插件，可能有多个钱包软件 */}
            <div className="flex h-24 w-96 items-center justify-between rounded-xl bg-[#1D1D1D] px-8 py-5">
              <img src={provider.info.icon} alt={provider.info.name} className=" h-14 w-16" />
              <p className=" text-center font-DMSansRegular text-xl font-medium text-white">{provider.info.name}</p>
            </div>
          </button>
        ))
      ) : (
        <div>Please install MetaMask or other wallet plugin.</div>
      )}
    </div>
  );
};
