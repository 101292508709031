import { createContext } from 'react';

export interface DataSourceEip6963Provider {
  wallets: Record<string, EIP6963ProviderDetail>;
  walletStatus: string;
  ethereumResult: string | null;
  selectedWallet: EIP6963ProviderDetail | null;
  selectedAccount: string | null;
  connectWallet: (walletUuid: EIP6963ProviderDetail) => Promise<void>;
  signInWithEthereum: () => Promise<void>;
}

declare global {
  interface WindowEventMap {
    'eip6963:announceProvider': CustomEvent;
  }
}

export const Eip6963ProviderContext = createContext<DataSourceEip6963Provider>({} as DataSourceEip6963Provider);
