// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, EffectCoverflow, Navigation, FreeMode } from 'swiper/modules';
import SwiperCore from 'swiper/core';
import HomeTextBox from 'src/components/HomeTextBox';
import switchBtn from 'src/assets/switchBtn.svg';

import BGdoor from 'src/assets/img/BGdoor.svg';
import BGdoor2 from 'src/assets/img/BGdoor2.svg';
import chain from 'src/assets/img/chain.svg';
import team1 from 'src/assets/img/team1.svg';
import team2 from 'src/assets/img/team2.svg';
import team4 from 'src/assets/img/team4.svg';
import team5 from 'src/assets/img/team5.svg';
import team6 from 'src/assets/img/team6.svg';
import team7 from 'src/assets/img/team7.svg';
import team8 from 'src/assets/img/team8.svg';
import sponsor1 from 'src/assets/img/sponsor1.svg';
import sponsor2 from 'src/assets/img/sponsor2.svg';
import sponsor3 from 'src/assets/img/sponsor3.svg';
import sponsorZAMA from 'src/assets/img/sponsorZAMA.svg';
import sponsor4 from 'src/assets/img/sponsor4.svg';
import sponsor5 from 'src/assets/img/sponsor5.svg';
import FHE from 'src/assets/img/FHE.svg';
import ceo from 'src/assets/img/ceo.svg';
import cto from 'src/assets/img/cto.svg';
import cso from 'src/assets/img/cso.svg';
import chief from 'src/assets/img/chief.svg';

SwiperCore.use([Autoplay, FreeMode]);

const NavButtons = () => {
  const swiper = useSwiper();
  return (
    <>
      {!swiper.isBeginning && (
        <div
          className="absolute left-1 right-auto top-1/2 flex h-[33px] w-[33px] -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center rounded-full border border-black bg-white p-2 hover:bg-btn1 sm:h-[50px] sm:w-[50px] sm:p-4"
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <img src={switchBtn} className="h-full w-full rotate-180" />
        </div>
      )}
      {!swiper.isEnd && (
        <div
          className="absolute left-auto right-1 top-1/2 flex h-[33px] w-[33px] -translate-y-1/2 translate-x-1/2 cursor-pointer items-center rounded-full border border-black bg-white p-2 hover:bg-btn1 sm:h-[50px] sm:w-[50px] sm:p-4"
          onClick={() => {
            swiper.slideNext();
          }}
        >
          <img src={switchBtn} className="h-full w-full" />
        </div>
      )}
    </>
  );
};

export default function Home() {
  return (
    <div className="h-full">
      {/* The first page */}
      <div className="hidden min-h-[1000px] bg-gradient lg:block">
        <div className="flex min-h-[1000px] -translate-y-32 items-center  justify-center gap-20 px-[100px] 3xl:px-60">
          <div className="flex flex-col items-start justify-center 3xl:translate-x-14">
            <div className="whitespace-nowrap font-InterBold text-5xl font-bold !leading-tight text-black xl:text-5xl 2xl:text-[64px]">
              Secure Computation <br />
              with FHE
            </div>
            <div className="mt-5 max-w-[612px] text-start font-InterRegular text-lg font-normal text-black xl:text-lg 2xl:text-xl">
              Sight AI is a Secure Computation Layer powered by verifiable Fully Homomorphic Encryption to secure the
              decentralized web, by processing intensive tasks like FHE and AI inference within Sight AI&apos;s
              decentralized network.
            </div>

            <button
              className="mt-[60px] inline-flex h-[60px] w-[310px] flex-col items-center justify-center gap-2.5 rounded-[70px] bg-black px-[70px] py-[15px] text-center font-InterRegular  text-xl font-medium uppercase text-white transition hover:-translate-x-[6px] hover:-translate-y-[6px] hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover"
              onClick={() => {
                window.open('https://forms.gle/VtSf5VSSNDKbkTKp8', '_blank');
              }}
            >
              Join Waitlist
            </button>
          </div>
          <img
            src={BGdoor}
            alt="GBdoor"
            className=" my-auto hidden -translate-y-10 translate-x-12 object-contain lg:block lg:w-[353px] xl:w-[381px] 2xl:w-[453px] 3xl:-translate-x-8"
          />
        </div>
      </div>
      {/* The second page */}
      <div className="-mt-[295px] hidden h-full items-center justify-center rounded-[100px] bg-white px-[100px]  pb-[100px] pt-[120px] lg:flex 3xl:px-60">
        <div className="flex w-full max-w-[1200px] flex-col gap-[60px]">
          <div className="text-right font-InterBold text-[40px] font-bold capitalize leading-snug text-black">
            Decentralized Web requires
            <br />
            <span>
              computation
              <span className="inline-flex h-[50px] w-[175px] items-center justify-center rounded-lg bg-btn1">
                security
              </span>
            </span>
          </div>
          <div className="relative flex items-stretch gap-[30px]">
            <img src={chain} alt="" className="absolute -top-[172px]" />
            <div className=" flex flex-col justify-end rounded-[30px] border border-black  bg-gradient-to-b from-btn1 to-white p-[60px] font-InterRegular text-xl font-normal leading-[33.60px] text-black shadow-offset-black">
              <div>
                Everything on the blockchain is public, exposing user data to mass surveillance and targeted theft. This
                hinders the mass adoption of use cases like fully on-chain games, decentralized AI, and secure DeFi.
              </div>
            </div>
            <div className=" flex flex-col gap-5 rounded-3xl bg-black p-[60px] font-InterRegular text-xl leading-[33.60px] text-white shadow-offset-green">
              FHE is an advanced encryption scheme that allows computation on encrypted data without needing decryption.
              Verifiable FHE is essential because delegating FHE computations to third parties can be vulnerable to
              malicious actors.
            </div>
          </div>
        </div>
      </div>
      {/* The third page */}
      <div className="hidden h-full items-center justify-center px-[100px] py-[100px] lg:flex 3xl:px-60">
        <div className="flex h-[691px] w-full max-w-[1239px] gap-7 2xl:gap-[70px]">
          <div className="flex flex-1 flex-col items-start justify-center">
            <div className="w-full  font-InterBold text-[40px] font-bold capitalize text-neutral-900 2xl:leading-normal">
              <span className="inline-flex h-[50px] items-center justify-center rounded-lg bg-btn1 px-2">
                Modular FHE
              </span>
              <br />
              Computation Layer
            </div>
            <div className="mb-16 mt-10 flex max-w-[373px] flex-col gap-5">
              <span className="font-InterRegular text-xl font-normal leading-normal text-neutral-900">
                Clients on other blockchains are able to offload their intensive computation tasks through Sight FHE
                Oracle for secure and efficient computation.
              </span>
              {/* <span className="flex font-InterRegular text-2xl font-normal leading-normal text-neutral-900">
                Legacy blockchains are not designed for AI inference -Sight AI is different.{' '}
              </span> */}
            </div>

            <button className="flex w-80 cursor-pointer flex-col items-center justify-center rounded-[70px] bg-black  px-16 py-3.5 text-center font-InterRegular  text-xl font-medium uppercase text-white transition  hover:-translate-x-[6px]  hover:-translate-y-[6px] hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover">
              OUR RESEARCH
            </button>
          </div>
          {/* 轮转窗口 */}
          <div className="mb-28 w-[684px] sm:mb-0  ">
            <Swiper
              effect={'coverflow'}
              initialSlide={1}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 45, // slide做3d倾斜时的角度
                stretch: 300, //  拉伸
                depth: 400, //  深度
                modifier: 1, // 修正值
                slideShadows: true, //  页面阴影
                scale: 0.95,
              }}
              slideActiveClass="swiper-slide-active"
              navigation={false}
              modules={[Autoplay, EffectCoverflow, Navigation]}
              autoplay={{
                delay: 250000,
                disableOnInteraction: false,
              }}
              simulateTouch={false}
              loop={false}
              className="mySwiper"
            >
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="llama7b" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="stablediffusion" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="moremodel" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* The fourth page */}
      <div className="mx-auto hidden h-[100hv-413px] w-full max-w-[1200px] flex-col items-center justify-center gap-[50px] bg-white  px-[100px] py-24 sm:flex-row lg:flex lg:h-[606px] 2xl:gap-[110px] 3xl:px-60">
        <img src={FHE} alt="" className="h-[389px] 2xl:h-[489px]" />
        <div className="flex  max-w-[583px] flex-col items-start justify-center gap-6 self-stretch 2xl:gap-14">
          <div className="self-stretch  font-InterBold text-5xl font-bold capitalize text-neutral-900">
            <span className="inline-flex h-[50px] w-[350px] items-center justify-center rounded-lg bg-btn1">
              Verifiable FHE
            </span>
          </div>
          <div className="font-InterRegular  font-normal leading-normal text-neutral-900 2xl:w-[583px] 2xl:text-xl">
            Verifiable FHE is essential to ensure integrity on blockchains.
            <br />
            <br />
            SPARC, Structure-Preserved Arguments for Computing, is an efficient proving system for verifiable FHE. This
            ongoing joint research effort is led by researchers from Sight AI, Zama, USC CS Theory Group, and Google
            Research.
          </div>
          <button className="w-80 rounded-[70px] bg-black px-16 py-3.5 text-center font-InterRegular text-xl font-medium uppercase text-white transition hover:-translate-x-[6px]  hover:-translate-y-[6px] hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover">
            DOCUMENTATION
          </button>
        </div>
        {/* <div className="relative mx-14 h-[413px] w-[1000px] xl:w-[1200px] 2xl:w-[1200px]">
          <div className="absolute left-0 top-[47px] inline-flex h-48 max-w-[653px] flex-col items-start justify-center gap-5 rounded-3xl border border-black bg-lime-100 p-10 shadow-offset-black lg:w-[450px] xl:w-[550px] 2xl:w-auto">
            <div className="self-stretch font-InterRegular text-xl font-bold capitalize text-neutral-900 xl:text-2xl">
              Blockchain Layer
            </div>
            <div className="self-stretch font-InterRegular text-xl font-normal text-neutral-900 xl:text-2xl">
              Layer 1 blockchain for consensus, settlement and transaction execution
            </div>
          </div>
          <div className="absolute left-[378px] top-[209px] inline-flex h-48 max-w-[687px] flex-col items-start justify-start gap-5 rounded-3xl border border-black bg-btn1 p-10 shadow-offset-black lg:w-[450px] xl:w-[550px] 2xl:w-auto">
            <div className="self-stretch font-InterRegular text-xl font-bold capitalize text-neutral-900 xl:text-2xl">
              Inference Layer
            </div>
            <div className="self-stretch font-InterRegular text-xl font-normal text-neutral-900 xl:text-2xl">
              Model hosting to execute and verify FHEML tasks, with collaborative inference
            </div>
          </div>
          <div className="absolute top-0 inline-flex h-40 max-w-[572px] flex-col items-start justify-center gap-5 rounded-3xl border border-black bg-neutral-50 p-10 shadow-offset-black lg:left-[568px] lg:w-[450px] xl:left-[648px] xl:w-[550px] 2xl:left-[868px] 2xl:w-auto">
            <div className="self-stretch font-InterRegular text-xl font-bold capitalize text-neutral-900 xl:text-2xl">
              Data Availability Layer
            </div>
            <div className="self-stretch font-InterRegular text-xl font-normal text-neutral-900 xl:text-2xl">
              Stores inference trace, output, and witness
            </div>
          </div>
        </div> */}
      </div>
      {/* The fifth page */}
      <div className="-mb-[100px] hidden min-h-[975px] w-full flex-col items-center justify-center gap-16 rounded-b-[100px]  bg-gradient  px-[100px] pb-[140px] pt-[100px] lg:relative lg:flex 3xl:px-60">
        <div className="w-96 text-center font-InterBold text-6xl font-bold capitalize text-neutral-900">Our Team</div>
        <div className="flex w-full max-w-[1200px] items-center justify-center gap-6">
          <div className="relative flex aspect-square flex-1 flex-col items-center justify-center rounded-full border border-black bg-white shadow-offset-black">
            <div className=" absolute flex h-full w-full flex-col items-center justify-center px-[61px]">
              <img className="h-full w-full  rounded-full" src={ceo} />
              <div className="flex -translate-y-10 flex-col text-center">
                <span className="font-InterBold text-lg font-bold capitalize leading-loose text-black">Brian</span>
                <span className="font-InterRegular text-lg font-normal capitalize leading-loose text-black">CEO</span>
              </div>
            </div>
          </div>
          <div className="relative flex aspect-square flex-1 flex-col items-center justify-center rounded-full border border-black bg-white shadow-offset-black">
            <div className=" absolute flex h-full w-full flex-col items-center justify-center px-[61px]">
              <img className="h-full w-full  rounded-full" src={cto} />
              <div className="flex -translate-y-10 flex-col text-center">
                <span className="font-InterBold text-lg font-bold capitalize leading-loose text-black">Arthur</span>
                <span className="font-InterRegular text-lg font-normal capitalize leading-loose text-black">CTO</span>
              </div>
            </div>
          </div>
          <div className="relative flex aspect-square flex-1 flex-col items-center justify-center rounded-full border border-black bg-white shadow-offset-black">
            <div className=" absolute flex h-full w-full flex-col items-center justify-center px-[61px]">
              <img className="h-full w-full  rounded-full" src={chief} />
              <div className="flex -translate-y-10 flex-col whitespace-nowrap text-center">
                <span className="font-InterBold text-lg font-bold capitalize leading-loose text-black">Jiapeng</span>
                <span className="font-InterRegular text-lg font-normal capitalize leading-loose text-black">
                  Chief Scientist
                </span>
              </div>
            </div>
          </div>
          <div className="relative flex aspect-square flex-1 flex-col items-center justify-center rounded-full border border-black bg-white shadow-offset-black">
            <div className=" absolute flex h-full w-full flex-col items-center justify-center px-[61px]">
              <img className="h-full w-full  rounded-full" src={cso} />
              <div className="flex -translate-y-10 flex-col whitespace-nowrap text-center">
                <span className="font-InterBold text-lg font-bold capitalize leading-loose text-black">Krys</span>
                <span className="font-InterRegular text-lg font-normal capitalize leading-loose text-black">CSO</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-[1200px] flex-col items-center justify-center gap-10 rounded-[60px] border border-black bg-team  py-20 shadow-offset-black">
          <div className=" flex h-full w-full items-center justify-center gap-12 px-44 2xl:gap-20">
            <img src={team1} className="h-full max-h-7 w-full xl:max-h-11" alt="" />
            <img src={team2} alt="" className="h-full max-h-8 w-full  xl:max-h-11" />
            <img src={team4} alt="" className="h-full max-h-8 w-full  xl:max-h-11" />
          </div>
          <div className="flex h-full w-full items-center justify-center gap-12 px-44 2xl:gap-24">
            <img src={team5} alt="" className="h-full max-h-8 w-full  xl:max-h-11" />
            <img src={team6} alt="" className="h-full max-h-8 w-full  xl:max-h-11" />
            <img src={team7} alt="" className="h-full max-h-8 w-full  xl:max-h-11" />
            <img src={team8} alt="" className="h-full   max-h-8 w-full xl:max-h-11" />
          </div>
        </div>
      </div>
      {/* The sixth page */}
      <div className="hidden h-full w-screen flex-col  items-center  justify-center  bg-black  px-[100px] lg:flex 3xl:px-60">
        <div className="pointer-events-none mt-48 inline-flex w-full max-w-[1200px] items-center justify-between overflow-x-auto ">
          <img src={sponsor1} alt="" className="h-full max-h-[40px] xl:max-h-[45px]  " />
          <img src={sponsor2} alt="" className="h-full max-h-[40px] xl:max-h-[64px] " />
          <img src={sponsor3} alt="" className="h-full max-h-[40px] xl:max-h-14  " />
          <img src={sponsorZAMA} alt="" className="h-full max-h-[18px] xl:max-h-5  " />
          <img src={sponsor4} alt="" className="h-full max-h-[40px] xl:max-h-14  " />
          <img src={sponsor5} alt="" className="h-full max-h-[40px] xl:max-h-[59px]" />
        </div>
        <div className=" mt-40 text-center font-InterBold text-6xl font-bold capitalize text-white">Our Advisors</div>
        <div className="mb-40 mt-16 flex max-w-[1200px] flex-col items-center justify-center gap-8 ">
          <div className="grid grid-cols-[auto,minmax(auto,1fr)] gap-10">
            <div className="inline-flex max-w-[500px] flex-col items-start justify-center rounded-[30px]  bg-btn1 px-16 py-5">
              <div className="font-InterBold text-2xl font-bold capitalize  text-black">Bruce Pon </div>
              <div className="font-InterRegular text-lg font-normal leading-7 text-black">
                Founder of Ocean Protocol. MIT Sloan
              </div>
            </div>
            <div className="inline-flex max-w-[660px] flex-col items-start justify-center rounded-[30px]  bg-white px-16 py-5">
              <div className="font-InterBold text-2xl font-bold capitalize  text-black">Shalini Wood</div>
              <div className="font-InterRegular text-lg font-normal leading-7 text-black">
                CMO of Babylonchain, ex-Honeywell, ex-Utrecht University
              </div>
            </div>
            <div className="inline-flex max-w-[500px] flex-col items-start justify-center rounded-[30px]  bg-white px-16 py-5">
              <div className="font-InterBold text-2xl font-bold capitalize  text-black">Qi Zhou </div>
              <div className="font-InterRegular text-lg font-normal leading-7 text-black">Founder of ETHStorage.</div>
            </div>
            <div className="inline-flex max-w-[660px] flex-col items-start justify-center rounded-[30px]  bg-btn1 px-16 py-5">
              <div className="font-InterBold text-2xl font-bold capitalize  text-black">Howie Xu </div>
              <div className="font-InterRegular text-lg font-normal leading-7 text-black">
                SVP at Palo Alto Network. Greylock EIR. TrustPath CEO.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* The first page cell phone */}
      <div className="block min-h-[763px] bg-phone px-5 pb-20 pt-14 lg:hidden">
        <div className="flex h-[calc(100%-61px)] items-center justify-center ">
          <div className="relative flex w-[335px] flex-col items-center justify-center">
            <div className="text-center font-InterBold text-4xl font-bold capitalize !leading-snug tracking-wide text-black">
              Secure Computation <br />
              with FHE
            </div>
            <div className="mt-5 w-[335px] text-center font-InterRegular text-base font-normal text-black lg:w-[845px] lg:text-start lg:text-2xl">
              Sight AI is a Secure Computation Layer powered by verifiable Fully Homomorphic Encryption to secure the
              decentralized web, by processing intensive tasks like FHE and AI inference within Sight AI&apos;s
              decentralized network.
            </div>
            <button
              className="mt-[40px] inline-flex flex-col items-center justify-center gap-2.5 rounded-[70px] bg-black px-[70px] py-[15px] text-center font-InterRegular text-base font-medium uppercase text-white transition hover:-translate-x-[6px] hover:-translate-y-[6px] hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover lg:text-2xl"
              onClick={() => {
                window.open('https://forms.gle/VtSf5VSSNDKbkTKp8', '_blank');
              }}
            >
              Join Waitlist
            </button>

            <div className="">
              <img src={BGdoor} alt="GBdoor" className="my-auto hidden h-[624px] w-[521px] lg:block" />
              <img src={BGdoor2} alt="GBdoor2" className="mt-6 block h-[268px] w-[231px] lg:hidden" />
            </div>
          </div>
        </div>
      </div>
      {/* The second page cell phone */}
      <div className="flex h-full w-full -translate-y-8 flex-col items-center justify-center gap-[30px] rounded-[30px] bg-white pb-4 pt-12 lg:hidden">
        <div className="w-[330px] text-nowrap text-start font-InterBold text-3xl font-bold capitalize leading-snug text-black">
          <div>Decentralized Web</div>
          <div>requires computation</div>
          <div className="inline-flex h-[38px] items-center justify-center rounded-lg bg-btn1 px-1">security</div>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={chain}
            alt=""
            className="absolute inline-flex h-[70px] w-[90px] -translate-y-2/3 scale-x-[-1] transform self-end"
          />
          <div className="mb-8 max-w-[335px] rounded-3xl border border-black bg-gradient-to-b from-btn1 to-white p-[20px] font-InterRegular text-sm font-normal leading-snug text-black shadow-offset-phone-black">
            Everything on the blockchain is public, exposing user data to mass surveillance and targeted theft. This
            hinders the mass adoption of use cases like fully on-chain games, decentralized AI, and secure DeFi.
          </div>
          <div className=" max-w-[335px] rounded-3xl bg-black p-[20px] font-InterRegular text-sm leading-snug text-white shadow-offset-phone-green">
            FHE is an advanced encryption scheme that allows computation on encrypted data without needing decryption.
            Verifiable FHE is essential because delegating FHE computations to third parties can be vulnerable to
            malicious actors.
          </div>{' '}
        </div>
      </div>
      {/* The third page cell phone */}
      <div className="flex h-full items-center justify-center px-8 lg:hidden">
        <div className="flex min-h-[850px] w-full max-w-[1439px] flex-col items-center gap-[30px]">
          <div className="flex flex-1 flex-col items-center justify-center gap-[25px]">
            <div className="w-[335px] font-InterBold text-3xl font-bold capitalize leading-snug text-black">
              Modular FHE
              <br />
              Computation
              <span className="inline-flex h-[38px] items-center justify-center rounded-lg bg-btn1 px-1"> Layer</span>
            </div>
            <div className="flex max-w-[295px] flex-col gap-5">
              <span className="font-InterRegular text-sm font-normal leading-snug text-neutral-900">
                Clients on other blockchains are able to offload their intensive computation tasks through Sight FHE
                Oracle for secure and efficient computation.
              </span>
              {/* <span className="flex font-InterRegular text-sm font-normal leading-snug text-neutral-900">
                Legacy blockchains are not designed for AI inference -Sight AI is different.
              </span> */}
            </div>

            <button className="flex cursor-pointer flex-col items-center justify-center rounded-3xl border border-black bg-black px-14 py-2 text-center  font-InterRegular text-base font-medium uppercase tracking-wide text-white transition  hover:-translate-x-[6px]  hover:-translate-y-[6px] hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover">
              OUR RESEARCH
            </button>
          </div>
          {/* 轮转窗口 */}
          <div className="w-screen max-w-[684px] lg:mb-0">
            <Swiper
              effect={'coverflow'}
              initialSlide={0}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 45, // slide做3d倾斜时的角度
                stretch: 100, //  拉伸
                depth: 400, //  深度
                modifier: 1, // 修正值
                slideShadows: true, //  页面阴影
              }}
              slideActiveClass="swiper-slide-active"
              navigation={false}
              modules={[Autoplay, EffectCoverflow, Navigation]}
              autoplay={{
                delay: 250000,
                disableOnInteraction: false,
              }}
              simulateTouch={false}
              loop={false}
              className="mySwiper"
            >
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="llama7b" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="stablediffusion" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
              <SwiperSlide className="m-auto !w-auto p-1">
                {({ isActive }) => (
                  <>
                    <HomeTextBox type="moremodel" />
                    {isActive && <NavButtons />}
                  </>
                )}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* The fourth page cell phone */}
      <div className="flex h-full w-full flex-col items-center justify-center  gap-[30px] bg-white py-[50px] lg:hidden">
        <div className="flex  flex-col items-center justify-center gap-[25px] self-stretch">
          <div className="self-stretch text-center font-InterBold text-3xl font-bold capitalize text-neutral-900">
            <span className="inline-flex h-[38px] items-center justify-center rounded-lg bg-btn1 px-1">Verifiable</span>{' '}
            FHE
          </div>
          <div className="max-w-[295px] text-center font-InterRegular text-sm font-normal leading-normal text-neutral-900">
            Verifiable FHE is essential to ensure integrity on blockchains. <br />
            SPARC, Structure-Preserved Arguments for Computing, is an efficient proving system for verifiable FHE. This
            ongoing joint research effort is led by researchers from Sight AI, Zama, USC CS Theory Group, and Google
            Research.
          </div>
          <button className="w-[253px] rounded-[50px] border border-black bg-black px-12 py-2.5 text-center font-InterRegular text-base font-medium uppercase text-neutral-50 transition hover:-translate-x-[6px] hover:-translate-y-[6px]  hover:bg-btn1 hover:text-black hover:shadow-offset-btn-hover">
            DOCUMENTATION
          </button>
        </div>
        {/* <div className="inline-flex h-80 w-80 flex-col items-start justify-start gap-5">
          <div className="flex h-28 flex-col items-center justify-center gap-2.5 rounded-2xl border border-black bg-lime-100 p-3.5 shadow-offset-phone-black">
            <div className="w-72 font-InterBold text-xl font-bold capitalize text-neutral-900">
              Blockchain Layer
            </div>
            <div className="w-72 font-InterRegular text-sm font-normal leading-tight text-neutral-900">
              Layer 1 blockchain for consensus, settlement and transaction execution
            </div>
          </div>
          <div className="flex h-20 flex-col items-center justify-center gap-2.5 rounded-2xl border border-black bg-white p-3.5 shadow-offset-phone-black">
            <div className="w-72 font-InterBold text-xl font-bold capitalize text-neutral-900">
              Data Availability Layer
            </div>
            <div className="w-72 font-InterRegular text-sm font-normal leading-tight text-neutral-900">
              Stores inference trace, output, and witness
            </div>
          </div>
          <div className="flex h-28 flex-col items-center justify-center gap-2.5 rounded-2xl border border-black bg-btn1 p-3.5 shadow-offset-phone-black">
            <div className="w-72 font-InterBold text-xl font-bold capitalize text-neutral-900">Inference Layer</div>
            <div className="w-72 font-InterRegular text-sm font-normal leading-tight text-neutral-900">
              Model hosting to execute and verify FHEML tasks, with collaborative inference
            </div>
          </div>
        </div> */}
      </div>
      {/* The fifth page cell phone */}
      <div className="flex min-h-[500px] w-full flex-col items-center justify-center gap-[65px] bg-black px-8 sm:flex-row lg:hidden">
        <div className="">
          <div className="flex h-full w-screen flex-col items-center justify-start gap-6 self-stretch rounded-b-[30px] bg-gradient pb-14">
            <div className="w-96 text-center font-InterBold text-3xl font-bold capitalize text-neutral-900">
              Our Team
            </div>
            <div className="grid max-w-[335px] grid-cols-2 flex-col items-stretch justify-start gap-5">
              <div className="relative h-[150px] w-[150px] rounded-full border border-black bg-white shadow-offset-phone-black">
                <img className="absolute left-[20px] top-[15px] w-[110px] rounded-full" src={ceo} />
                <div className="absolute left-[20px] top-[103px] w-28 text-center leading-3">
                  <span className="font-InterBold text-sm font-bold capitalize  text-black">
                    Brian <br />
                  </span>
                  <span className="font-InterRegular text-[10px] font-normal capitalize  text-black">CEO</span>
                </div>
              </div>
              <div className="relative h-[150px] w-[150px] rounded-full border border-black bg-white shadow-offset-phone-black">
                <img className="absolute left-[20px] top-[15px] w-[110px] rounded-full" src={cto} />
                <div className="absolute left-[20px] top-[103px] w-28 text-center leading-3">
                  <span className="font-InterBold text-sm font-bold capitalize text-black">
                    Arthur <br />
                  </span>
                  <span className="font-InterRegular text-[10px] font-normal capitalize  text-black">CTO</span>
                </div>
              </div>
              <div className="relative h-[150px] w-[150px] rounded-full border border-black bg-white shadow-offset-phone-black">
                <img className="absolute left-[20px] top-[15px] w-[110px] rounded-full" src={chief} />
                <div className="absolute left-[20px] top-[103px] w-28 text-center leading-3">
                  <span className="font-InterBold text-sm font-bold capitalize text-black">
                    Jiapeng
                    <br />
                  </span>
                  <span className="font-InterRegular text-[10px] font-normal capitalize text-black">
                    Chief Scientist
                  </span>
                </div>
              </div>
              <div className="relative h-[150px] w-[150px] rounded-full border border-black bg-white shadow-offset-phone-black">
                <img className="absolute left-[20px] top-[15px] w-[110px] rounded-full" src={cso} />
                <div className="absolute left-[20px] top-[103px] w-28 text-center leading-3">
                  <span className="font-InterBold text-sm font-bold capitalize text-black">
                    Krys
                    <br />
                  </span>
                  <span className="font-InterRegular text-[10px] font-normal capitalize text-black">CSO</span>
                </div>
              </div>
            </div>
            <div className="mx-3 inline-flex flex-col items-center justify-center gap-8 rounded-[60px] border border-black bg-team px-16 py-12 shadow-offset-phone-black">
              <img src={team7} alt="" className=" w-[187px]" /> <img src={team5} alt="" className=" w-[187px]" />
              <img src={team2} alt="" className=" w-[187px]" /> <img src={team6} alt="" className="w-[187px]" />
              <img src={team4} alt="" className=" w-[104px]" />
              <img src={team8} alt="" className=" w-[128px]" /> <img src={team1} alt="" className=" w-[146px]" />
            </div>
          </div>
        </div>
      </div>
      {/* The sixth page cell phone */}
      <div className="flex h-full  w-screen flex-col  items-center  justify-center  gap-[25px] bg-black px-8 lg:hidden ">
        <div className="mb-[25px] mt-[50px] inline-flex h-[102px] w-full max-w-[315px] flex-col items-center justify-between">
          <div className="flex w-full items-center justify-between">
            <img src={sponsor1} alt="" className="h-[30px]  " />
            <img src={sponsor2} alt="" className="h-[36px]" />
            <img src={sponsorZAMA} alt="" className="h-4" />
          </div>
          <div className="flex w-full items-center justify-between">
            <img src={sponsor3} alt="" className="h-[32px]" />
            <img src={sponsor4} alt="" className="h-[32px]" />
            <img src={sponsor5} alt="" className="h-[32px]" />
          </div>
        </div>
        <div className="text-center font-InterBold text-3xl font-bold capitalize text-white">Our Advisors</div>
        <div className="inline-flex max-w-[335px] flex-col items-stretch justify-center gap-5">
          <div className="flex flex-col items-start justify-center rounded-2xl bg-btn1 px-5 py-3.5">
            <div className="text-center font-InterBold text-base font-bold capitalize leading-snug text-black">
              Bruce Pon{' '}
            </div>
            <div className="text-center font-InterRegular text-sm font-normal leading-tight text-black">
              Founder of Ocean Protocol. MIT Sloan
            </div>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white px-5 py-3.5">
            <div className="text-center font-InterBold text-base font-bold capitalize leading-snug text-black">
              Shalini Wood
            </div>
            <div className=" font-InterRegular text-sm font-normal leading-tight text-black">
              CMO of Babylonchain, ex-Honeywell, ex-Utrecht University{' '}
            </div>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-btn1 px-5 py-3.5">
            <div className="text-center font-InterBold text-base font-bold capitalize leading-snug text-black">
              Qi Zhou{' '}
            </div>
            <div className="text-center font-InterRegular text-sm font-normal leading-tight text-black">
              Founder of ETHStorage.
            </div>
          </div>
          <div className="flex flex-col items-start justify-center rounded-2xl bg-white px-5 py-3.5">
            <div className="text-center font-InterBold text-base font-bold capitalize leading-snug text-black">
              Howie Xu{' '}
            </div>
            <div className="w-80 font-InterRegular text-sm font-normal leading-tight text-black">
              SVP at Palo Alto Network. Greylock EIR. <br />
              TrustPath CEO.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
